import { defineStore, acceptHMRUpdate } from 'pinia'

export const useNavStore = defineStore('nav', {
  state: () => {
    return {
      loading: false,
      sidebarCollapsed: false,
      title: '',
      clientSide: false,
    }
  },
  actions: {
    setTitle(title: string) {
      this.title = title
    },
    setClientSide(clientSide: boolean) {
      this.clientSide = clientSide
    }
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useNavStore, import.meta.hot))

